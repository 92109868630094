<template>
  <div>
    <div class="iq-card p-1">
      <div class="row">
        <div class="col-md-4 my-1">
          <select class="form-control"  v-model="filter.project_id" @change="getPackageFinancial">
            <option selected disabled value="">select Project...</option>
            <option v-for="(i, key) in getUserProjects" :key="key" :value="i.id">
              <span>{{i.en_title}}</span>
            </option>
          </select>
        </div>

        <div class="col-md-4 my-1">
          <select class="form-control" id="packages"  v-model="filter.package_id" @change="getPackageFinancial">
            <option selected disabled value="">select Packages...</option>
            <option v-for="(i , key) in allPackages" :key="key" :value="i.id">
              {{ i.title }}
            </option>
          </select>
        </div>
        <div class="col-md-12 my-1">
          <date-range-picker
              class="w-100"
              ref="picker"
              opens=false
              :date-format="dateFormat"
              :timePicker=false
              :timePicker24Hour=false
              :showWeekNumbers=true
              :showDropdowns=true
              format='mm/dd/yyyy'
              direction="center"
              :autoApply=true
              v-model="dateRange"
              :linkedCalendars=true
              customRangeLabel= 'Custom Range'
              @update="getPackageFinancial"
          >
          </date-range-picker>
        </div>
        <div class="col-md-1 d-flex justify-content-center align-items-center" v-if="filter.project_id ||
        dateRange.startDate || dateRange.endDate || filter.package_id">
          <span class="text-danger cursor-pointer" @click="resetFilter()">Clear All</span>
        </div>
      </div>
    </div>

    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">Package Financial Report  <b-badge variant="primary"> {{ pagination.total }} </b-badge></h4>
      </template>
      <template v-slot:body>
        <div>
          <h5>Total Out Standing: {{allTotals.out_standing}}</h5>
          <h5>total Pay : {{allTotals.pay}}</h5>
          <h5>total Price : {{allTotals.price}}</h5>
          <h5>total Roc : {{allTotals.roc}}</h5>
        </div>
        <div v-if="loadTable" class="text-center">
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </div>
        <b-table v-else striped responsive :fields="columns" :items="alldata" class="mb-0 table-borderless">
          <template v-slot:cell(member)="data">
            <router-link class="linkedReport" :to="{name: 'profile', params:{ id : data.item.member.id }}"><b-badge variant="primary">{{ data.item.member.name }}</b-badge></router-link>
          </template>
          <template v-slot:cell(member)="data">
            <router-link class="linkedReport" :to="{name: 'profile', params:{ id : data.item.member.id }}"><b-badge variant="primary">{{ data.item.member.name }}</b-badge></router-link>
          </template>
        </b-table>
        <b-pagination v-model="pagination.current_page"
                      class="mt-3"
                      :total-rows="pagination.total"
                      align="right"
                      :per-page="pagination.per_page"
                      aria-controls="my-table"
                      @input="getPackageFinancial()"
        ></b-pagination>
      </template>
    </iq-card>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import financialServices from '../services/report'
import packageService from '@/modules/packages/services/package'
import { mapGetters } from 'vuex'
export default {
  name: 'packageFinancialReport',
  data () {
    return {
      loadTable: false,
      allPackages: [],
      alldata: [],
      allTotals: [],
      filter: {
        project_id: '',
        package_id: ''
      },
      dateRange: {
        startDate: '',
        endDate: ''
      },
      columns: [
        {
          label: 'Member',
          key: 'member'
        },
        {
          label: 'Package',
          key: 'package_title'
        },
        {
          label: 'Period Expire At',
          key: 'period_expire_at'
        },
        {
          label: 'Date',
          key: 'date'
        },
        {
          label: 'Price',
          key: 'price'
        },
        {
          label: 'Roc',
          key: 'roc'
        },
        {
          label: 'Pay',
          key: 'pay'
        },
        {
          label: 'Out Standing',
          key: 'out_standing'
        },
        {
          label: 'Available',
          key: 'available'
        },
        {
          label: 'project',
          key: 'project.en_title'
        },
        {
          label: 'Member Payment Oldest',
          key: 'member_payment_oldest.openinig_balance'
        }
      ],
      pagination: {
        total: 0,
        current_page: 1,
        per_page: 10
      },
      animated: { enter: 'fadeInUp', exit: 'fadeOut' }
    }
  },
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  methods: {
    resetFilter () {
      this.filter = {
        project_id: '',
        package_id: ''
      }
      this.dateRange = {
        startDate: '',
        endDate: ''
      }
      this.getPackageFinancial()
    },
    getAllPackages () {
      packageService.getAllPackageOrService({}).then(response => {
        this.allPackages = response.data.data
      })
    },
    getPackageFinancial () {
      this.loadTable = true
      financialServices.getPackageFinancial({
        ...this.filter,
        page: this.pagination.current_page,
        date_from: this.dateRange.startDate ? new Date(this.dateRange.startDate).toJSON().slice(0, 10) : '',
        date_to: this.dateRange.endDate ? new Date(this.dateRange.endDate).toJSON().slice(0, 10) : ''
      }).then(res => {
        console.log(res.data.data.packages.data)
        this.alldata = res.data.data.packages.data
        this.allTotals = res.data.data.totals
        this.pagination = {
          total: res.data.data.packages.total,
          current_page: this.pagination.current_page,
          per_page: res.data.data.packages.per_page
        }
      }).finally(() => {
        this.loadTable = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getPackageFinancial()
    this.getAllPackages()
  }
}
</script>
<style>
</style>
